
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mapContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.sidebar-container {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Custom Scrollbar Styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d93953; /* Customize the scrollbar thumb color */
  border-radius: 10px; /* Make the scrollbar rounded */
  min-height: 50px; /* Ensure a minimum thumb size */
  border: 3px solid transparent; /* Create space around the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #f48432; /* Change color on hover */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Customize the scrollbar track color */
}
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
