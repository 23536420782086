.mapContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
  
  .geocoder {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }
  
  .categories {
    position: absolute;
    top: 50px;
    left: 10px;
    z-index: 1;
    background: white;
    padding: 10px;
    border-radius: 5px;
  }
  
  .categories button {
    margin: 5px;
    padding: 5px 10px;
    border: none;
    background: #0078a8;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .categories button.active {
    background: #005f7f;
  }
  
  .booth-cards {
    margin-top: 10px;
    max-height: 300px;
    overflow-y: auto;
  }
  
  .booth-card {
    background: #f1f1f1;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .booth-card h3 {
    margin: 0;
    font-size: 16px;
  }
  
  .booth-card p {
    margin: 5px 0 0;
    font-size: 14px;
  }
  
  .categories {
    padding: 10px;
    max-width: 624px;
    margin: 0 auto; /* Center the container */
  }
  
  .categories button {
    margin: 5px;
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .categories button.active {
    background-color: #0056b3;
  }
  
  .booth-cards {
    margin-top: 20px;
  }
  
  .booth-card {
    display: flex;
    background: white;
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    overflow: hidden;
  }
  
  .booth-card-image img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  
  .booth-card-content {
    padding: 10px;
    flex: 1;
  }
  
  .booth-card-content h3 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
  
  .booth-card-content p {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .view-button {
    padding: 5px 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }

/* App.css */
.booth-marker {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  color: white;
  padding: 2px 5px;
  text-align: center;
  white-space: nowrap;
  pointer-events: none;
  transform: translate(-50%, -100%);
}

.booth-marker::after {
  content: '';
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

/* Default styles (desktop view) */
.desktop-tasks-bar {
  position: fixed;
  top: 0;
  width: 100%;
  display: block  !important;
}

.mobile-tasks-bar {
  display: none  !important;
}

/* Mobile view */
@media only screen and (max-width: 768px) {
  .desktop-tasks-bar {
    display: none !important;
  }

  .mobile-tasks-bar {
    position: fixed;
    bottom: env(safe-area-inset-bottom, 0);
    width: 100%;
    display: block !important;
    height: 90px; /* Adjust height as needed */
    background: #fff; /* Example styling */
  }

  body {
    padding-bottom: 60px; /* Account for the fixed bar height */
  }
}

@media only screen and (max-width: 768px) and (orientation: landscape) {
  /* Landscape-specific adjustments */
  .mobile-tasks-bar {
    bottom: 0; /* Override if needed */
  }
}


.category-selector {
  background-color: #fff;
  border-radius: 10px;
  max-height: 90vh; /* 70% of the viewport height */
  /* margin: auto; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* position: relative; */
}

.gettingHere-selector {
  max-height: 90vh;
  margin: auto;
  position: absolute;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.category-group {
}

.category-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: bold;
}

.category-items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.category-item {
  padding: 2px 16px;
  background-color: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.category-item:hover {
  background-color: #e0e0e0;
}

.category-item.selected {
  background-color: #ff7f7f;
  color: #fff;
}

.filter-button {
  display: block;
  width: 100%;
  padding: 5px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  margin-top: 8px;
}

.custom-scrollbar {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #d93953 transparent; /* Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.mapboxgl-ctrl {
  z-index: 1000; /* Ensure controls have a higher z-index */

}

.mapboxgl-canvas {
  z-index: 0; /* Ensure the map itself is behind the controls */
}
a.mapboxgl-ctrl-logo{
  display: none;
}

.custom-dashed {
  border-top: none; /* No actual border, only the dashed line */
  background-image: linear-gradient(to right, #d1d5db 50%, transparent 50%);
  background-size: 6px 0.5px; /* Dash size (10px) and gap (2px) */
  background-repeat: repeat-x;
}

@media (orientation: landscape) {
  .control-container {
      flex-direction: row; /* Adjust the flex direction */
      justify-content: center; /* Center controls */
  }
}

@media (orientation: portrait) {
  .control-container {
      flex-direction: column; /* Stack controls vertically */
  }
}

.control-container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping */
  justify-content: space-between;
}
